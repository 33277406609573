/**
 * 获取字典中的名称
 */
import store from '../store/index'

// 干品冻品
const getProdTypeName = (prodType) => {
  let row = store.state.goods.prodType.find(item => item.value === prodType)
  return row ? row.label : ''
}
// 重量单位
const getWeightUnitName = (weightUnit) => {
  let row = store.state.goods.weightUnit.find(item => item.value === weightUnit)
  return row ? row.label : ''
}
//出货类型
const getDeliveryTypeName = (deliveryType) => {
  let row = store.state.stock.shipTypeList.find(item => {
    return item.erpId.toString() === deliveryType
  })
  return row ? row.name : ''
}
//结算方式
const getSettlementMethodName = (settlementMethod) =>{
  let row = store.state.stock.settlementList.find(item => {
    return item.stockflowscode === settlementMethod
  })
  return row ? row.stockflowsname : ''
}
//发票类型
const getInvoiceTypeName = (invoiceType) =>{
  let row = store.state.stock.invoiceTypeList.find(item => {
    return item.stockflowsid === invoiceType
  })
  return row ? row.stockflowsname : ''
}

//年龄阶段
const getAgeScopeName = (ageScope) =>{
  let row = store.state.common.ageScopeList.find(item => {
    return item.value === ageScope
  })
  return row ? row.name : ''
}

//等级
const getCourseLevelName = (courseLevel) =>{
  let row = store.state.common.courseLevelList.find(item => {
    return item.value === courseLevel
  })
  return row ? row.name : ''
}

//分类
const getCourseTypeName = (courseType) =>{
  let row = store.state.common.courseTypeList.find(item => {
    return item.value === courseType
  })
  return row ? row.name : ''
}

//领域
const getCourseFieldName = (courseField) =>{
  let row = store.state.common.courseFieldList.find(item => {
    return item.value === courseField
  })
  return row ? row.name : ''
}

//课程版本
const getCourseVersionName = (courseVersion) =>{
  let row = store.state.common.courseVersionList.find(item => {
    return item.value === courseVersion
  })
  return row ? row.name : ''
}


//课程专题
const getCourseThemeName = (courseTheme) =>{
  let row = store.state.common.courseThemeList.find(item => {
    return item.value === courseTheme
  })
  return row ? row.name : ''
}

const getTaskTypeName = (taskType) =>{
  return '111'
}

export {
  getProdTypeName,
  getWeightUnitName,
  getDeliveryTypeName,
  getSettlementMethodName,
  getInvoiceTypeName,
  getTaskTypeName,
  getAgeScopeName,
  getCourseLevelName,
  getCourseTypeName,
  getCourseFieldName,
  getCourseVersionName,
  getCourseThemeName
}
